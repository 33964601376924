import { useToast } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { authedDelete, authedGet, authedPost } from "src/api";

interface ZendeskConnectionStatus {
  isConnected?: boolean;
  subdomain?: string;
}

interface SlackConnectionStatus {
  isConnected?: boolean;
  teamName?: string;
  teamId?: string;
}

interface TrelloConnectionStatus {
  orgId: string;
  orgDisplayName: string;
  orgUrl: string;
}

interface IntegrationContextIF {
  setZendeskSubdomain: (subdomain: string) => Promise<boolean>;
  connectZendesk: (
    code: string,
    redirectUrl: string,
    scope: string
  ) => Promise<void>;
  zendeskConnectionStatus?: ZendeskConnectionStatus;
  disconnectZendesk: () => Promise<void>;
  connectSlack: (code: string, redirectUrl: string) => Promise<void>;
  slackConnectionStatus?: SlackConnectionStatus;
  disconnectSlack: () => Promise<void>;
  connectTrello: (token: string) => Promise<void>;
  trelloConnectionStatus?: TrelloConnectionStatus;
  disconnectTrello: () => Promise<void>;
}

export const IntegrationContext = React.createContext<IntegrationContextIF>({
  setZendeskSubdomain: () =>
    new Promise((_, reject) => {
      reject("setZendeskSubdomain not ready");
    }),
  connectZendesk: () =>
    new Promise((_, reject) => {
      reject("connectZendesk not ready");
    }),
  zendeskConnectionStatus: {},
  disconnectZendesk: () =>
    new Promise((_, reject) => {
      reject("disconnectZendesk not ready");
    }),
  connectSlack: () =>
    new Promise((_, reject) => {
      reject("connectSlack not ready");
    }),
  slackConnectionStatus: {},
  disconnectSlack: () =>
    new Promise((_, reject) => {
      reject("disconnectSlack not ready");
    }),
  connectTrello: () =>
    new Promise((_, reject) => {
      reject("connectTrello not ready");
    }),
  disconnectTrello: () =>
    new Promise((_, reject) => {
      reject("disconnectTrello not ready");
    }),
});

const IntegrationContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const toast = useToast();
  const [
    zendeskConnectionStatus,
    setZendeskConnectionStatus,
  ] = useState<ZendeskConnectionStatus>();
  const [
    slackConnectionStatus,
    setSlackConnectionStatus,
  ] = useState<SlackConnectionStatus>();
  const [
    trelloConnectionStatus,
    setTrelloConnectionStatus,
  ] = useState<TrelloConnectionStatus>();

  const getZendeskConnectionStatus = useCallback(async () => {
    const status = await authedGet("/integrations/zendesk/status").then(
      (resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          return {};
        }
      }
    );
    setZendeskConnectionStatus(status);
  }, []);

  useEffect(() => {
    getZendeskConnectionStatus();
  }, [getZendeskConnectionStatus]);

  const setZendeskSubdomain = useCallback(
    async (subdomain: string) => {
      const resp = await authedPost("/integrations/zendesk/configure", {
        subdomain,
      });
      if (resp.ok) {
        setZendeskConnectionStatus({
          subdomain: zendeskConnectionStatus?.subdomain,
        });
      }
      return resp.ok;
    },
    [zendeskConnectionStatus?.subdomain]
  );

  const connectZendesk = useCallback(
    async (code: string, redirectUrl: string, scope: string) => {
      const resp = await authedPost("/integrations/zendesk/connect", {
        code,
        redirectUrl,
        scope,
      });
      if (resp.ok) {
        toast({
          status: "success",
          title: "Connected",
          description: "The Zendesk integration has been connected",
          variant: "darkSuccess",
        });
        getZendeskConnectionStatus(); // refresh
      } else {
        toast({
          status: "error",
          title: "Whoops",
          description: `The Zendesk integration connection failed with ${resp.statusText}`,
        });
      }
    },
    [getZendeskConnectionStatus, toast]
  );

  const disconnectZendesk = useCallback(async () => {
    const resp = await authedDelete("/integrations/zendesk/disconnect");
    if (resp.ok) {
      toast({
        status: "success",
        title: "Connected",
        description: "The Zendesk integration has been disconnected",
        variant: "darkSuccess",
      });
      setZendeskConnectionStatus({
        isConnected: false,
        subdomain: zendeskConnectionStatus?.subdomain,
      });
    } else {
      toast({
        status: "error",
        title: "Whoops",
        description: `The Zendesk integration disconnection failed with ${resp.statusText}`,
      });
    }
  }, [toast, zendeskConnectionStatus?.subdomain]);

  const getSlackConnectionStatus = useCallback(async () => {
    const status = await authedGet("/integrations/slack/status").then(
      (resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          return {};
        }
      }
    );
    setSlackConnectionStatus(status);
  }, []);

  useEffect(() => {
    getSlackConnectionStatus();
  }, [getSlackConnectionStatus]);

  const connectSlack = useCallback(
    async (code: string, redirectUrl: string) => {
      const resp = await authedPost("/integrations/slack/connect", {
        code,
        redirectUrl,
      });
      if (resp.ok) {
        toast({
          status: "success",
          title: "Connected",
          description: "The Slack integration has been connected",
          variant: "darkSuccess",
        });
        getSlackConnectionStatus(); // refresh
      } else {
        toast({
          status: "error",
          title: "Whoops",
          description: `The Slack integration connection failed with ${resp.statusText}`,
        });
      }
    },
    [getSlackConnectionStatus, toast]
  );

  const disconnectSlack = useCallback(async () => {
    const resp = await authedDelete("/integrations/slack/disconnect");
    if (resp.ok) {
      toast({
        status: "success",
        title: "Connected",
        description: "The Slack integration has been disconnected",
        variant: "darkSuccess",
      });
      setSlackConnectionStatus({
        isConnected: false,
        teamName: "",
        teamId: "",
      });
    } else {
      toast({
        status: "error",
        title: "Whoops",
        description: `The Slack integration disconnection failed with ${resp.statusText}`,
      });
    }
  }, [toast]);

  const getTrelloConnectionStatus = useCallback(async () => {
    const status = await authedGet("/integrations/trello/status").then((resp) =>
      resp.json()
    );
    setTrelloConnectionStatus(status);
  }, []);

  useEffect(() => {
    getTrelloConnectionStatus();
  }, [getTrelloConnectionStatus]);

  const connectTrello = useCallback(
    async (token: string) => {
      const resp = await authedPost("/integrations/trello/connect", {
        token,
      });
      if (resp.ok) {
        toast({
          status: "success",
          title: "Connected",
          description: "The Trello integration has been connected",
          variant: "darkSuccess",
        });
        getTrelloConnectionStatus();
      } else {
        toast({
          status: "error",
          title: "Whoops",
          description: `The Trello integration connection failed with ${resp.statusText}`,
        });
        setTrelloConnectionStatus(undefined);
      }
    },
    [toast, getTrelloConnectionStatus]
  );

  const disconnectTrello = useCallback(async () => {
    const resp = await authedDelete("/integrations/trello/disconnect");
    if (resp.ok) {
      toast({
        status: "success",
        title: "Connected",
        description: "The Trello integration has been disconnected",
        variant: "darkSuccess",
      });
      setTrelloConnectionStatus(undefined);
    } else {
      toast({
        status: "error",
        title: "Whoops",
        description: `The Trello integration disconnection failed with ${resp.statusText}`,
      });
    }
  }, [toast]);

  return (
    <IntegrationContext.Provider
      value={{
        setZendeskSubdomain,
        connectZendesk,
        zendeskConnectionStatus,
        disconnectZendesk,
        slackConnectionStatus,
        connectSlack,
        disconnectSlack,
        connectTrello,
        trelloConnectionStatus,
        disconnectTrello,
      }}
    >
      {children}
    </IntegrationContext.Provider>
  );
};

export default IntegrationContextProvider;
