const Link = {
  variants: {
    blue: {
      color: "blue.700",
      _hover: { color: "blue.900" },
    },
    yellow: {
      color: "yellow.700",
      _hover: { color: "yellow.900" },
    },
  },
};

export default Link;
